* {
  margin: 0;
  box-sizing: border-box;
}

.iphone-chip {
  width: 171px;
  height: 35px;
  background: #a626bf;
  border-radius: 50px;
  position: absolute;
  top: 155px;
  left: 582px;
  font-weight: 700;
  font-size: 16px;
  color: white;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
}

.iphone14 {
  width: 88.3px;
  height: 125px;
  position: absolute;
  top: 73px;
  left: 550px;
}

.notyf-biud {
  color: red;
  font-family: Outfit;
  font-weight: 700;
  font-size: 20px;
}

#main-registration {
  height: 100vh;
  width: 100vw;
  background: radial-gradient(100% 100% at 50% 0%, #d81656 0%, #8b002e 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  background: #d81656;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.24);
  position: absolute;
  bottom: 0;
  left: 0;
}

.outline-btn {
  background: transparent;
  border: 2px solid #ffffff;
  width: 684px;
  height: 80px;
  border-radius: 50px;
  padding: 8px 16px 8px 16px;
  color: #fff;
  font-family: Outfit;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.title {
  color: #fff;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 100%;
}

.title-1 {
  position: absolute;
  left: 618px;
  top: 232px;
  width: 684px;
  height: 256px;
}

.title-2 {
  position: absolute;
  left: 500px;
  top: 191px;
  width: 708px;
  height: 128px;
}

.title-3 {
  position: absolute;
  left: 500px;
  top: 212px;
  width: 920px;
  height: 256px;
}

.title-4a {
  width: 920px;
  height: 256px;
  position: absolute;
  top: 127px;
  left: 500px;
}

.title-4b {
  width: 775px;
  height: 256px;
  position: absolute;
  top: 127px;
  left: 500px;
}

.title-4c {
  width: 920px;
  height: 256px;
  position: absolute;
  top: 119px;
  left: 500px;
}

.title-5 {
  width: 920px;
  height: 320px;
  position: absolute;
  top: 148px;
  left: 500px;
}

.title-6 {
  position: absolute;
  left: 500px;
  top: 96px;
  width: 920px;
  height: 256px;
}

.title-7 {
  position: absolute;
  width: 920px;
  height: 192px;
  left: 500px;
  top: 257px;
}

.banner {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 680px;
  /* border: 1px solid black; */
  /* top: 20px; */
  /* z-index: 1; */
}

.input {
  width: 684px;
  height: 80px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}

.name-input {
  position: absolute;
  padding: 8px 3px 8px 24px;
  top: 375px;
  left: 500px;
  /* margin-top: 56px; */
}

.wpp-input {
  font-size: 20px;
  line-height: 120%;
  background: url(../../images/wpp.svg) no-repeat 20px 28px;
  padding-left: 55px;
  letter-spacing: 1px;
}

.wpp-input-1 {
  position: absolute;
  left: 500px;
  top: 471px;
}

.wpp-input-2 {
  position: absolute;
  left: 500px;
  top: 448px;
}

.obs-container {
  color: #fff;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

.obs-container-name {
  position: absolute;
  top: 471px;
  left: 500px;
}

.obs-container-wpp {
  position: absolute;
  top: 567px;
  left: 500px;
}

.obs-container-wpp-friend {
  position: absolute;
  left: 500px;
  top: 544px;
  width: 862px;
  height: 24px;
}

.obs-container-friend {
  position: absolute;
  width: 862px;
  height: 24px;
  top: 576px;
  left: 500px;
}

.icon {
  margin-right: 10px;
  width: 14.65px;
  height: 11.33px;
}

.nav-container {
  position: absolute;
  top: 56px;
  left: 56px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
}

.circle-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50px;
  background: transparent;
}

.continue-btn {
  width: 213px;
  height: 80px;
  border-radius: 50px;
  border: none;
  background: #fff;
  font-family: Outfit;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #d81656;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-btn-1 {
  position: absolute;
  top: 375px;
  left: 1207px;
}

.continue-btn-2 {
  position: absolute;
  top: 471px;
  left: 1207px;
}

.continue-btn-3 {
  position: absolute;
  top: 448px;
  left: 1207px;
}

.disabled-btn {
  opacity: 0.48;
}

.enabled-btn {
  opacity: 1;
}

.business-options-container {
  display: flex;
  flex-direction: row;
  gap: 56px;
}

.round-button {
  background: #fff;
  border: none;
  width: fit-content;
  height: 80px;
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.iphone-btn {
  background: #fff;
  border-radius: 50px;
  border: none;
  width: 920px;
  height: 80px;
  padding: 8px 16px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #d81656;
  /* display: flex;
  align-items: center; */
}

.final-btn {
  background: transparent;
  width: 920px;
  height: 80px;
  border: 2px solid #fff;
  border-radius: 50px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #fff;
}

.iphone13 {
  width: 136px;
  height: 112px;
  position: absolute;
  left: 508px;
  top: 72px;
}

.label-tel {
  position: absolute;
  left: 500px;
  top: 423px;
  width: 920px;
  height: 24px;
}

.label-ind {
  position: absolute;
  left: 500px;
  top: 508px;
  width: 802px;
  height: 58px;
}

.label-wpp-friend {
  position: absolute;
  width: 920px;
  height: 24px;
  left: 500px;
  top: 400px;
}

.label {
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #fff;
}

.btns-container {
  display: flex;
  flex-direction: column;
}

.round-button:nth-of-type(1) {
  width: 311px;
}

.round-button:nth-of-type(2) {
  width: 231px;
}

.round-button:nth-of-type(3) {
  width: 318px;
}

.round-button span {
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #d81656;
}

.round-button img {
  width: 24px;
}

.hg-theme-default {
  background-color: #d81656;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue,
    Helvetica, Arial, Lucida Grande, sans-serif;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 60%;
}

.hg-theme-num {
  background-color: #d81656;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue,
    Helvetica, Arial, Lucida Grande, sans-serif;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 25%;
}

.hg-button span {
  pointer-events: none;
  font-weight: bold;
  font-family: Outfit;
  font-size: 24px;
  color: #fff;
}

.button.hg-button {
  border-width: 0;
  font-size: inherit;
  outline: 0;
}

.hg-button {
  display: inline-block;
  flex-grow: 1;
}

.hg-row {
  display: flex;
}

.hg-row:not(:last-child) {
  margin-bottom: 5px;
}

.hg-row .hg-button-container,
.hg-row .hg-button:not(:last-child) {
  margin-right: 5px;
}

.hg-row > div:last-child {
  margin-right: 0;
}

.hg-row .hg-button-container {
  display: flex;
}

.hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 75px;
  justify-content: center;
  padding: 5px;
}

.hg-button-bksp {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: url(../../images/backspace.svg) center no-repeat;
  background-size: 32px;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  padding: 5px;
}

.hg-button-bksp-normal {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: url(../../images/backspace.svg) center no-repeat;
  background-size: 32px;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 75px;
  justify-content: center;
  padding: 5px;
}

.hg-button-zero {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  padding: 5px;
  justify-self: center;
  margin-left: 33.33%;
}

.hg-button.hg-standardBtn {
  width: 20px;
}

.hg-button.hg-activeButton {
  background: #efefef;
  color: #000;
}

.hg-layout-numeric .hg-button {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 33.3%;
}

.hg-button.hg-button-numpadadd,
.hg-button.hg-button-numpadenter {
  height: 85px;
}

.hg-button.hg-button-numpad0 {
  width: 105px;
}

.hg-button.hg-button-com {
  max-width: 85px;
}

.hg-button.hg-standardBtn.hg-button-at {
  max-width: 45px;
}

.hg-button.hg-selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: #fff;
}

.hg-button.hg-standardBtn[data-skbtn=".com"] {
  max-width: 82px;
}

.hg-button.hg-standardBtn[data-skbtn="@"] {
  max-width: 60px;
}

.hg-candidate-box {
  background: #ececec;
  border-bottom: 2px solid #b5b5b5;
  border-radius: 5px;
  display: inline-flex;
  margin-top: -10px;
  max-width: 272px;
  position: absolute;
  transform: translateY(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

ul.hg-candidate-box-list {
  display: flex;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

li.hg-candidate-box-list-item {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

li.hg-candidate-box-list-item:hover {
  background: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

li.hg-candidate-box-list-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.hg-candidate-box-prev:before {
  content: "◄";
}

.hg-candidate-box-next:before {
  content: "►";
}

.hg-candidate-box-next,
.hg-candidate-box-prev {
  align-items: center;
  background: #d0d0d0;
  color: #969696;
  cursor: pointer;
  display: flex;
  padding: 0 10px;
}

.hg-candidate-box-next {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.hg-candidate-box-prev {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.hg-candidate-box-btn-active {
  color: #444;
}

.notyf__icon--error,
.notyf__icon--success {
  height: 21px;
  width: 21px;
  background: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  position: relative;
}
.notyf__icon--error:after,
.notyf__icon--error:before {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
  width: 3px;
  border-radius: 3px;
  left: 9px;
  height: 12px;
  top: 5px;
  color: red;
}
.notyf__icon--error:after {
  transform: rotate(-45deg);
}
.notyf__icon--error:before {
  transform: rotate(45deg);
}
.notyf__toast--disappear .notyf__icon,
.notyf__toast--disappear .notyf__message {
  -webkit-animation: notyf-fadeoutdown 0.3s forwards;
  animation: notyf-fadeoutdown 0.3s forwards;
  opacity: 1;
  transform: translateY(0);
}
.notyf__icon {
  width: 22px;
  text-align: center;
  font-size: 1.3em;
  opacity: 0;
  -webkit-animation: notyf-fadeinup 0.3s forwards;
  animation: notyf-fadeinup 0.3s forwards;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  margin-right: 13px;
}

/* @-webkit-keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(25%);
  }
}
@keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(25%);
  }
}
@-webkit-keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(25%);
  }
}
@keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(25%);
  }
}
@-webkit-keyframes ripple {
  0% {
    transform: scale(0) translateY(-45%) translateX(13%);
  }
  to {
    transform: scale(1) translateY(-45%) translateX(13%);
  }
}
@keyframes ripple {
  0% {
    transform: scale(0) translateY(-45%) translateX(13%);
  }
  to {
    transform: scale(1) translateY(-45%) translateX(13%);
  }
}
.notyf {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  box-sizing: border-box;
  padding: 20px;
}
.notyf__icon--error,
.notyf__icon--success {
  height: 21px;
  width: 21px;
  background: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  position: relative;
}
.notyf__icon--error:after,
.notyf__icon--error:before {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
  width: 3px;
  border-radius: 3px;
  left: 9px;
  height: 12px;
  top: 5px;
}
.notyf__icon--error:after {
  transform: rotate(-45deg);
}
.notyf__icon--error:before {
  transform: rotate(45deg);
}
.notyf__icon--success:after,
.notyf__icon--success:before {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
  width: 3px;
  border-radius: 3px;
}
.notyf__icon--success:after {
  height: 6px;
  transform: rotate(-45deg);
  top: 9px;
  left: 6px;
}
.notyf__icon--success:before {
  height: 11px;
  transform: rotate(45deg);
  top: 5px;
  left: 10px;
}
.notyf__toast {
  display: block;
  overflow: hidden;
  pointer-events: auto;
  -webkit-animation: notyf-fadeinup 0.3s ease-in forwards;
  animation: notyf-fadeinup 0.3s ease-in forwards;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 0 15px;
  border-radius: 2px;
  max-width: 300px;
  transform: translateY(25%);
  box-sizing: border-box;
  flex-shrink: 0;
}
.notyf__toast--disappear {
  transform: translateY(0);
  -webkit-animation: notyf-fadeoutdown 0.3s forwards;
  animation: notyf-fadeoutdown 0.3s forwards;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.notyf__toast--disappear .notyf__icon,
.notyf__toast--disappear .notyf__message {
  -webkit-animation: notyf-fadeoutdown 0.3s forwards;
  animation: notyf-fadeoutdown 0.3s forwards;
  opacity: 1;
  transform: translateY(0);
}
.notyf__toast--disappear .notyf__dismiss {
  -webkit-animation: notyf-fadeoutright 0.3s forwards;
  animation: notyf-fadeoutright 0.3s forwards;
  opacity: 1;
  transform: translateX(0);
}
.notyf__toast--disappear .notyf__message {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.notyf__toast--upper {
  margin-bottom: 20px;
}
.notyf__toast--lower {
  margin-top: 20px;
}
.notyf__toast--dismissible .notyf__wrapper {
  padding-right: 30px;
}
.notyf__ripple {
  height: 400px;
  width: 400px;
  position: absolute;
  transform-origin: bottom right;
  right: 0;
  top: 0;
  border-radius: 50%;
  transform: scale(0) translateY(-51%) translateX(13%);
  z-index: 5;
  -webkit-animation: ripple 0.4s ease-out forwards;
  animation: ripple 0.4s ease-out forwards;
}
.notyf__wrapper {
  display: flex;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 15px;
  border-radius: 3px;
  position: relative;
  z-index: 10;
}
.notyf__icon {
  width: 22px;
  text-align: center;
  font-size: 1.3em;
  opacity: 0;
  -webkit-animation: notyf-fadeinup 0.3s forwards;
  animation: notyf-fadeinup 0.3s forwards;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  margin-right: 13px;
}
.notyf__dismiss {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 26px;
  margin-right: -15px;
  -webkit-animation: notyf-fadeinleft 0.3s forwards;
  animation: notyf-fadeinleft 0.3s forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
  opacity: 0;
}
.notyf__dismiss-btn {
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  outline: none;
  opacity: 0.35;
  height: 100%;
  width: 100%;
}
.notyf__dismiss-btn:after,
.notyf__dismiss-btn:before {
  content: "";
  background: #fff;
  height: 12px;
  width: 2px;
  border-radius: 3px;
  position: absolute;
  left: calc(50% - 1px);
  top: calc(50% - 5px);
}
.notyf__dismiss-btn:after {
  transform: rotate(-45deg);
}
.notyf__dismiss-btn:before {
  transform: rotate(45deg);
}
.notyf__dismiss-btn:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.15);
}
.notyf__dismiss-btn:active {
  opacity: 0.8;
}
.notyf__message {
  vertical-align: middle;
  position: relative;
  opacity: 0;
  -webkit-animation: notyf-fadeinup 0.3s forwards;
  animation: notyf-fadeinup 0.3s forwards;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  line-height: 1.5em;
}
@media only screen and (max-width: 480px) {
  .notyf {
    padding: 0;
  }
  .notyf__ripple {
    height: 600px;
    width: 600px;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .notyf__toast {
    max-width: none;
    border-radius: 0;
    box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.13);
    width: 100%;
  }
  .notyf__dismiss {
    width: 56px;
  }
} */
